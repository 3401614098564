<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <div v-else-if="surveyMetric">
    <b-card>
      <h4>{{ surveyTitle }} Insights</h4>
      <b-row>
        <b-col
          sm="12"
          class="text-left"
        >
          <b-row>
            <b-col>
              <p class="mb-0">
                Participant:
              </p>
              <b-badge
                :variant="STATUS_COLOR['Active']"
                class="mr-1"
              >
                {{ mentorSynonym }}
              </b-badge>
              <b-badge :variant="STATUS_COLOR['Active']">
                {{ menteeSynonym }}
              </b-badge>
            </b-col>
            <b-col sm="4">
              <div class="d-flex justify-content-end">
                <b-form-group
                  label="Select Groups to filter by"
                  label-for="groups"
                  class="mb-0"
                >
                  <aom-group-filter
                    v-model="selectedGroup"
                    :program-id="$route.params.id"
                  />
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col sm="3">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="48"
                  variant="light-primary"
                  class="mr-2"
                >
                  <feather-icon icon="PieChartIcon" />
                </b-avatar>
                <div class="d-flex flex-column">
                  <h4 class="font-weight-bolder mb-0">
                    {{ surveyResponses }}
                  </h4>
                  <p class="card-text font-small-3 mb-0">
                    Responses
                  </p>
                </div>
              </div>
            </b-col>

            <b-col sm="3">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="48"
                  variant="light-success"
                  class="mr-2"
                >
                  <feather-icon icon="UsersIcon" />
                </b-avatar>
                <div class="d-flex flex-column">
                  <h4 class="font-weight-bolder mb-0">
                    {{ surveyEngagement }}
                  </h4>
                  <p class="card-text font-small-3 mb-0">
                    Engagement
                  </p>
                </div>
              </div>
            </b-col>

            <b-col sm="3">
              <div
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                  class="mr-2"
                >
                  <aom-icon
                    icon="NoteIcon"
                    size="14"
                  />
                </b-avatar>
                <div class="d-flex flex-column">
                  <h4 class="font-weight-bold mb-0">
                    {{ surveyMetric.questions_count }}
                  </h4>
                  <p>Questions</p>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col sm="3">
              <div
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                  class="mr-2"
                >
                  <feather-icon icon="UsersIcon" />
                </b-avatar>
                <div class="d-flex flex-column">
                  <h4 class="font-weight-bolder mb-0">
                    {{ surveyMetric.user_forms_started }}
                  </h4>
                  <p class="card-text font-small-3 mb-0">
                    Started
                  </p>
                </div>
              </div>
            </b-col>

            <b-col sm="3">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="48"
                  variant="light-success"
                  class="mr-2"
                >
                  <feather-icon icon="UsersIcon" />
                </b-avatar>
                <div class="d-flex flex-column">
                  <h4 class="font-weight-bolder mb-0">
                    {{ surveyMetric.user_forms_completed }}
                  </h4>
                  <p class="card-text font-small-3 mb-0">
                    Completed
                  </p>
                </div>
              </div>
            </b-col>

            <b-col sm="3">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="48"
                  variant="light-danger"
                  class="mr-2"
                >
                  <feather-icon icon="UsersIcon" />
                </b-avatar>
                <div class="d-flex flex-column">
                  <h4 class="font-weight-bolder mb-0">
                    {{ surveyMetric.user_forms_not_started }}
                  </h4>
                  <p class="card-text font-small-3 mb-0">
                    Not started
                  </p>
                </div>
              </div>
            </b-col>

            <b-col
              sm="3"
              class="d-flex align-items-center justify-content-end text-right"
            >
              <b-button
                variant="primary"
                @click="viewAllReponses"
              >
                View all responses
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-for="(question, index) in surveyMetric.questions"
      :key="index"
    >
      <!-- Rating scale -->
      <div v-if="question.type_id === questionTypes.SCALE">
        <b-row>
          <b-col sm="12">
            <h4 class="text-left font-weight-bold m-0 mb-2">
              {{ question.translations[0].question_text }}
            </h4>
          </b-col>

          <b-col sm="12">
            <b-row class="d-flex align-items-center mb-3">
              <b-col sm="3" />
              <!-- Options -->
              <b-col>
                <b-row>
                  <b-col
                    v-for="(choice, chIndex) in question.choices"
                    :key="chIndex"
                    class="font-weight-bold text-center"
                  >
                    {{ choice.translations[0].choice_text }}
                  </b-col>
                </b-row>
              </b-col>
              <!-- End Options -->
            </b-row>

            <!-- Statements -->
            <b-row
              v-for="(statement, stIndex) in question.statements"
              :key="stIndex"
              class="d-flex align-items-center mb-3"
            >
              <b-col
                sm="3"
                class="text-left pb-1"
              >
                {{ statement.translations[0].question_text }}
              </b-col>

              <b-col>
                <b-row>
                  <b-col
                    v-for="(choice, chIndex) in question.choices"
                    :key="chIndex"
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <apexchart
                      type="radialBar"
                      :options="
                        getPercentage(statement.answers, choice) > 50
                          ? currentMatchPercentageGreenChartOptions
                          : currentMatchPercentageChartOptions
                      "
                      :series="[getPercentage(statement.answers, choice)]"
                      height="100"
                    />
                    <div class="survey-metric-rating-responses">
                      {{ getNumberResponses(statement.answers, choice) }}
                      /
                      {{ getTotalResponses(statement.answers) }}
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- End Statements -->
          </b-col>
        </b-row>
      </div>

      <!-- Multiple choice -->
      <div v-if="question.type_id === questionTypes.CHOICE">
        <b-row>
          <b-col sm="12">
            <h4 class="text-left font-weight-bold m-0 mb-1">
              {{ question.translations[0].question_text }}
            </h4>
          </b-col>

          <b-col sm="12">
            <b-row class="d-flex align-items-center mb-1">
              <b-col
                sm="3"
                class="text-left text-truncate"
              />
              <b-col
                sm="7"
                class="text-center"
              />
              <b-col
                sm="2"
                class="text-center"
              >
                Responses
              </b-col>
            </b-row>
            <b-row
              v-for="(choice, chIndex) in question.choices"
              :key="chIndex"
              class="d-flex align-items-center mb-1"
            >
              <b-col
                sm="3"
                class="text-left text-truncate"
              >
                {{ choice.translations[0].choice_text }}
              </b-col>
              <b-col sm="6">
                <b-progress
                  :value="getPercentage(question.answers, choice)"
                  class="mr-1"
                  :class="
                    getPercentage(question.answers, choice) > 50 ?
                      'default-green-chart-color' :
                      'default-chart-color'
                  "
                />
              </b-col>
              <b-col
                sm="1"
                class="text-left"
              >
                {{ getPercentage(question.answers, choice) }}%
              </b-col>
              <b-col
                sm="2"
                class="text-center"
              >
                <b-badge
                  variant="light-success"
                  class="font-weight-normal"
                >
                  {{ getNumberResponses(question.answers, choice) }}
                </b-badge>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BProgress,
  BAvatar,
  BBadge,
  BFormGroup
} from "bootstrap-vue";
import formsService from "@/services/formsService";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { AOM_GREEN_COLOR } from "@/libs/utils";
import AomGroupFilter from '@aom-core/AomGroupFilter.vue';
import { makeErrorToast } from "@/libs/utils";
import { mapGetters } from 'vuex';
import { userRoles, userRolesDisplay, questionTypes } from '@models';
import { STATUS_COLOR } from "@/libs/utils";
import AomIcon from "@aom-core/AomIcon";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BProgress,
    BAvatar,
    BBadge,
    AomSkeletonLoader,
    AomGroupFilter,
    BFormGroup,
    AomIcon
  },
  data() {
    return {
      STATUS_COLOR,
      defaultChartColor: "#FFA500",
      defaultGreenChartColor: AOM_GREEN_COLOR,
      defaultChartOptions: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          height: 180,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: "60%",
            },
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "30%",
              margin: 5, // margin is in pixels
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "14px",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91],
          },
        },
        stroke: {
          lineCap: "round",
        },
      },
      isLoading: false,
      survey: null,
      surveyMetric: null,
      selectedGroup: null,
      questionTypes,
    };
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),

    surveyResponses() {
      if (!this.surveyMetric) { return '0/0'; }

      return `${this.surveyMetric.user_forms_completed}/${this.surveyMetric.user_forms_count}`;
    },
    surveyEngagement() {
      let percentage = 0;
      if (this.surveyMetric && this.surveyMetric.user_forms_count) {
        percentage = Math.round((this.surveyMetric.user_forms_completed * 100) / this.surveyMetric.user_forms_count);
      }

      return `${percentage}%`;
    },
    currentMatchPercentageGreenChartOptions() {
      return {
        ...this.defaultChartOptions,
        colors: [this.defaultGreenChartColor],
      };
    },

    currentMatchPercentageChartOptions() {
      return { ...this.defaultChartOptions, colors: [this.defaultChartColor] };
    },

    surveyTitle() {
      if (this.survey) {
        return this.survey.translations[0].title;
      }
      return '';
    },

    mentorSynonym() {
      if (this.defaultProgram && this.defaultProgram.program_roles.length) {
        const programRole = this.defaultProgram.program_roles.find(programRole => {
          if (programRole.role_id === userRoles.MENTOR) {
            return programRole;
          }
        });
        return programRole && programRole.alternative_role_name ? programRole.alternative_role_name : userRolesDisplay[userRoles.MENTOR];
      }
      return userRolesDisplay[userRoles.MENTOR];
    },

    menteeSynonym() {
      if (this.defaultProgram && this.defaultProgram.program_roles.length) {
        const programRole = this.defaultProgram.program_roles.find(programRole => {
          if (programRole.role_id === userRoles.MENTEE) {
            return programRole;
          }
        });
        return programRole && programRole.alternative_role_name ? programRole.alternative_role_name : userRolesDisplay[userRoles.MENTEE];
      }
      return userRolesDisplay[userRoles.MENTEE];
    },
  },
  watch: {
    selectedGroup() {
      if (this.selectedGroup) {
        this.loadProgramSurveyMetric(this.selectedGroup);
      }
    }
  },
  created() {
    this.loadSurvey();
    this.loadProgramSurveyMetric();
  },
  methods: {
    async loadSurvey() {
      try {
        this.isLoading = true;
        // Load survey detail
        const surveyResponse = await formsService.getProgramSurvey(
          this.$route.params.id,
          this.$route.params.survey
        );
        this.survey = surveyResponse.data;
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async loadProgramSurveyMetric(selectedGroup) {
      try {
        this.isLoading = true;

        // Filter by group
        const surveyMetricResponse = selectedGroup
          ? await formsService.getProgramSurveyMetric(
            this.$route.params.id,
            this.$route.params.survey,
            selectedGroup
          )
          : await formsService.getProgramSurveyMetric(
            this.$route.params.id,
            this.$route.params.survey
          );
        this.surveyMetric = {
          ...surveyMetricResponse.data,
          questions: surveyMetricResponse.data.questions.filter(
            // Temporary show rating scale and multi choices
            question =>
              question.type_id === questionTypes.SCALE ||
              question.type_id === questionTypes.CHOICE
          ),
        };
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    getTotalResponses(answers) {
      if (!answers) {
        return 0;
      }
      return answers.length;
    },
    getNumberResponses(answers, choice) {
      if (!answers) {
        return 0;
      }
      return answers.filter(a => a.choice_id === choice.id).length;
    },
    getPercentage(answers, choice) {
      if (!answers) {
        return 0;
      }
      return (
        answers.length 
          ? parseFloat(
              ((answers.filter(a => a.choice_id === choice.id).length * 100) / answers.length).toFixed(2)
            )
          : 0
      );
    },
    viewAllReponses() {
      this.$router.push({
        name: "champion-program-survey-result",
        params: {
          id: this.$route.params.id,
          survey: this.surveyMetric.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-metric-rating-responses {
  margin-top: -12px;
  font-size: 12px;
}
::v-deep .default-chart-color {
  .progress-bar {
    background-color: #FFA500 !important;
  }
}
::v-deep .default-green-chart-color {
  .progress-bar {
    background-color: #9bcc65 !important;
  }
}
</style>
